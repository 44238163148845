import { Section } from "@components/UI-Elements/General";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import Layout from "../../components/layout";
import CoinList from "../../components/Modules/dashboard/coin-table";
import NewsApiList from "../../components/Modules/news/newsapi";
import SEO from "../../components/seo";
import Card from "../../components/UI-Elements/Card";
import GoogleTrends from "../../components/UI-Elements/GoogleTrend";
import LinkList, { LinkItem } from "../../components/UI-Elements/Lists/LinkList";
import List from "../../components/UI-Elements/Lists/List";

const pages: LinkItem[] = [
  { href: "https://blockworks.co/the-investors-guide-to-defi-2-0/", title: "The Investor’s Guide to DeFi 2.0" },
  { href: "https://www.fidor.de/blog/defi", title: "Fidor Bank: DeFi - Entwicklung, Risiken und Zukunft" },
  { href: "https://www.defipulse.com", title: "DeFi Pulse" },
  { href: "https://dune.xyz/hagaetc/stablecoins", title: "Dune Analytics - Stablecoins Dashboard" },
  { href: "https://blog.cakedefi.com/de/", title: "Cake DeFi Blog" },
];

// Was ist eine DEX?
const anwendungen = [
  "Stable Coins",
  "Dezentrale Kredite",
  "Dezentrale Zahlungsdienstleistungen",
  "Dezentrale Börsen",
  "Dezentrale Derivate",
];

const DeFiIndex = () => {
  return (
    <Layout>
      <SEO keywords={['defi charts', 'decentralized finance']} title="DeFi Charts" robots="noindex" />
      <Title>DeFi (Decentralized Finance)</Title>
      <div className="grid md:grid-cols-2 gap-4">
        <Section>
          <div>
            <Title level={2}>📄 Informationen</Title>
          </div>
          <Card>
            <LinkList links={pages.map((page) => ({ ...page, external: true }))} />
          </Card>
        </Section>
        <Section>
          <div className="grid gap-4">
            <Card title="Was ist DeFi?">
              <p className="pb-2">
                Der Begriff DeFi steht für Decentralized Finance bzw. Dezentrale Finanzdienstleistungen und ist die Bezeichnung für
                Finanzdienstleistungen, welche auf Blockchain Technologien basieren. Diese werden deshalb nicht durch eine zentrale
                Autorität kontrolliert oder gesteuert.
              </p>
              <Title level={4}>Anwendungen</Title>
              <List items={anwendungen} />
            </Card>
          </div>
        </Section>
      </div>
      <Section>
        <Title level={2}>5 DeFi Kryptowährungen</Title>
        <CoinList coins={["uniswap", "terra-luna", "aave", "maker", "compound-governance-token"]} />
      </Section>
      <Section>
        <Title level={2}>Wie sieht das Interesse aus?</Title>
        <Card>
          <p className="mb-3">
            Nimmt das Thema DeFi wieder an Fahrt auf? Interessant ist es auch das Thema DeFi im Vergleich zu NFTs oder Metaverse zu
            beobachten.
          </p>
          <GoogleTrends time="today 12-m" keywords={["DeFi", "NFT", "Metaverse", "Web 3.0"]} />
        </Card>
      </Section>
      <Section>
        <Title level={2}>Aktuelle News zu DeFi?</Title>
        <NewsApiList keyword={'decentralized finance'} pageSize={6} />
      </Section>
    </Layout>
  );
};

export default DeFiIndex;
